import React, { useEffect, useState } from "react";
import "./App.css";
import Cookies from "js-cookie";
import useSound from "use-sound";

function App() {
  /*
  const notPop = "https://cdn.cutebear.in.th/images/popbear-idle.png";
  const pop = "https://cdn.cutebear.in.th/images/popbear-onclick.png";
  */
  const popSFX = "https://cdn.cutebear.in.th/sounds/pop.mp3";

  const getValuefromCookies = () => {
    const cookies = Cookies.get("pop_count");
    let totalValue;
    if (cookies) {
      totalValue = parseInt(cookies);
    }
    return totalValue;
  };

  const [play] = useSound(popSFX, { volume: 0.4 });

  const [isClick, setClick] = useState(false);
  const [isPopped, sIsPopped] = useState(false);
  const [counter, setCounter] = useState(0);
  const [confirm, setConfirm] = useState(0);
  const [country, setCountry] = useState("");
  const [click, addClick] = useState(getValuefromCookies() || 0);

  useEffect(() => {
    fetch("https://ipinfo.io/json?token=502785e3e21851")
      .then((response) => response.json())
      .then((data) => {
        const detectedCountry = data.country;
        setCountry(detectedCountry);
        Cookies.set("country", country, { expires: 365 });
      })
      .catch((error) => {
        console.error("Error fetching:", error);
      });
  }, [country]);

  const notClick = async () => {
    setClick(false);
  };

  const onResetButtonClick = async () => {
    setConfirm(confirm + 1)

    if (confirm === 1) {
       addClick(0)
       Cookies.set("pop_count", (0).toString(), { expires: 365 });

       setConfirm(0)
    }
  }

  const setIsPopped = () => {
    sIsPopped(true);

    setTimeout(() => {
      sIsPopped(false);
    }, 100);
  }

  const onClick = async () => {
    getValuefromCookies();
    play();
    setClick(true);
    setIsPopped()
    addClick(click + 1);
    setCounter(counter + 1);
    Cookies.set("pop_count", (click + 1).toString(), { expires: 365 });
  };

  return (
    <>
      <div id="clickarea" onPointerDown={onClick} onPointerUp={notClick}>
        <header>
          <h1 className="title">POPBEAR</h1>
          <link
            rel="preload"
            as="image"
            href="https://api.cutebear.in.th/v1/image?name=popbear-idle"
          />
          <link
            rel="preload"
            as="image"
            href="https://api.cutebear.in.th/v1/image?name=popbear-onclick"
          />
          <h2 className={`number ${isPopped ? "pp" : ""}`}>
            {click.toLocaleString()}
          </h2>
        </header>
        <div
          id="pop-image"
          className={`image ${isClick === false ? "np" : "p"}`}
        ></div>
      </div>
      <div id="bottomarea">
        <div className="bottom-items">
          <ul className="left-items">
            <li></li>
          </ul>
          <ul className="right-items">
            <li>
              <button onClick={onResetButtonClick}>
                {confirm === 0 ? "Reset" : "Sure?"}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <footer>
        <div className="footer-items">
          <span className="copyright-brand">
            &copy; {new Date().getFullYear()} cutebear.in.th.
            Inspired by{" "}
            <a
              className="inner-link"
              href="https://popcat.click"
              target="_blank"
              rel="noopener noreferrer"
            >
              Popcat.click
            </a>
            {". "}
          </span>
          <ul className="footer-links">
            <li>
              <a
                href="https://cutebear.in.th/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Links
              </a>
            </li>
            <li>•</li>
            <li>
              <a
                href="https://www.tiktok.com/@socute_whitebear"
                target="_blank"
                rel="noopener noreferrer"
              >
                TikTok
              </a>
            </li>
            <li>•</li>
            <li>
              <a
                href="https://www.roblox.com/users/757251905/profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                Roblox
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default App;
